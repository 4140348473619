const config = {
  ENVIRONMENT_NAME: 'prod',
  forwoodId: {
    URL: 'https://id.macquarie.forwoodsafety.com',
    APP_CLIENT_ID: '489etjtb0rmc935cl4gl43jbrc',
    APIGATEWAY: 'https://ekts4ykuf7.execute-api.eu-west-1.amazonaws.com/prod'
  },
  apiGateway: {
    REGION: 'eu-west-1',
    URL: 'https://p2vzbk0ji0.execute-api.eu-west-1.amazonaws.com/prod'
  },
  reactApp: {
    ENVIRONMENT_NAME: 'prod',
    VERSION: 'incidents-container-1.7.0',
    HOSTNAME: 'https://incidents.macquarie.forwoodsafety.com',
    INCIDENTS_ACCESS_ROLE: 'IncidentsAccess',
    CREATE_INCIDENT_URL: 'https://iframe.macquarie.forwoodsafety.com/node/add/isi',
    INCIDENT_LIST_URL: 'https://iframe.macquarie.forwoodsafety.com/isi-list',
    IFRAME_ORIGIN_URL: 'https://iframe.macquarie.forwoodsafety.com'
  },
  get forwoodIdAuthUrl() {
    return `${this.forwoodId.URL}/authorize?response_type=token&scope=openid&client_id=${this.forwoodId.APP_CLIENT_ID}&redirect_uri=${this.reactApp.HOSTNAME}&state=`;
  }
};

export default config;

